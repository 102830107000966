import React from 'react';
import { DefaultType } from 'types/default';

import { ButtonType } from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import {
  StyledCite,
  StyledIcon,
  StyledImage,
  StyledMeetTeamLink,
  StyledName,
  StyledTextWrapper,
  StyledWrapper,
} from './opinion-section.styles';

export type OpinionSectionType = {
  cite: string;
  fullName: string;
  position: string;
  isSlider?: boolean;
  linkButton?: ButtonType;
  isDark?: boolean;
  photoAbsolute?: boolean;
  image: WpImageType;
} & DefaultType;

const OpinionSection = ({
  cite,
  fullName,
  position,
  linkButton,
  image,
  margin,
  padding,
  mobilePadding,
  mobileMargin,
  maxWidth,
  bgColor,
  isDark,
  photoAbsolute,
}: OpinionSectionType) => (
  <StyledWrapper
    margin={margin}
    padding={padding}
    mobilePadding={mobilePadding}
    mobileMargin={mobileMargin}
    bgColor={bgColor}
    maxWidth={maxWidth}
  >
    <StyledTextWrapper>
      <StyledIcon name="quote" isDark={isDark} />
      <StyledCite color={isDark ? 'gray100' : 'white'} variant="title11" html={cite} />
      <StyledName color={isDark ? 'gray100' : 'white'} variant="body1" html={fullName} />
      <Typography color={isDark ? 'gray100' : 'whiteAlpha72'} variant="body1" html={position} />
      {linkButton && (
        <StyledMeetTeamLink>
          <Typography
            color={isDark ? 'gray100' : 'white'}
            variant="title4"
            html={linkButton.label}
          />
          {linkButton?.icon && <StyledIcon name={linkButton?.icon.name} rotate />}
        </StyledMeetTeamLink>
      )}
    </StyledTextWrapper>
    <StyledImage photoAbsolute={photoAbsolute}>
      <WpImage {...image} />
    </StyledImage>
  </StyledWrapper>
);

export default OpinionSection;
