import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.grayscaleGray0};
  padding-block: ${({ theme }) => theme.gap.mdl};

  ${breakpoint.md} {
    padding-inline: ${({ theme }) => theme.gap.sm};
  }

  ${breakpoint.lg} {
    padding-block: ${({ theme }) => theme.gap.xl};
  }

  .swiper {
    &-wrapper {
      justify-content: flex-start;
    }
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    flex-direction: row;
  }
`;
export const StyledButton = styled(Button)`
  ${breakpoint.md} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  padding-left: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding: 0;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  margin-bottom: ${({ theme }) => theme.gap.md};
  padding-right: ${({ theme }) => theme.gap.sm};
  position: relative;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.sm};
`;

export const StyledSubHeading = styled(Typography)`
  padding-top: ${({ theme }) => theme.gap.xs};
`;
