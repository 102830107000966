import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

const meetTeamWidth = '505px';
const heightImage = '504px';
const widthImage = '540px';

export const StyledWrapper = styled.div<DefaultType>`
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  margin: ${({ theme, mobileMargin }) => mobileMargin || `${theme.gap.md} 0 0 0 `};
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  padding: ${({ mobilePadding }) => mobilePadding || `0`};

  ${breakpoint.lg} {
    margin: ${({ theme, margin }) => margin || `${theme.gap.xl} 0 0 0 `};
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledTextWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.gap.md};

  ${breakpoint.lg} {
    margin-top: ${({ theme }) => theme.gap.md};
    max-width: ${meetTeamWidth};
  }
`;
export const StyledCite = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin-top: ${({ theme }) => theme.gap.smd};
  }
`;

export const StyledName = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
  ${breakpoint.lg} {
    margin-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledMeetTeamLink = styled.div`
  margin-top: ${({ theme }) => theme.gap.md};
  display: flex;
  text-decoration: underline;
  text-decoration-color: white;
  text-underline-offset: ${({ theme }) => theme.gap.xxs};
  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledIcon = styled(Icon)<{ rotate?: boolean; isDark?: boolean }>`
  color: ${({ theme, isDark }) => (isDark ? theme.colors.primary50 : theme.colors.white)};
  transform: ${({ rotate }) => rotate && 'rotate(45deg)'};
`;

export const StyledImage = styled.div<{ photoAbsolute?: boolean }>`
  margin-bottom: ${({ theme }) => theme.gap.sm};
  width: 100%;
  ${breakpoint.lg} {
    position: ${({ photoAbsolute }) => photoAbsolute && 'absolute'};
    height: ${heightImage};
    width: ${widthImage};
    z-index: 5;
    top: 0;
    right: 0;
  }
`;
