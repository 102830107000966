import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Container from 'components/atoms/container';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  padding-block: ${({ theme }) => theme.gap.mdl};
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.transparent};

  ${breakpoint.lg} {
    margin: ${({ margin }) => margin};
    padding-block: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.wrapperXs};
`;

export const StyledTextIntent = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    padding-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledListValues = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.smd} 0;

  ${breakpoint.lg} {
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.gap.sm} 0;
  }
`;
export const StyledListItemValue = styled.li`
  width: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.primary50};
  padding-left: ${({ theme }) => theme.gap.sm};
  font-weight: 700;
  font-size: 16px;

  ${breakpoint.lg} {
    font-size: 20px;
    width: calc(100% / 3);
  }
`;
