/* eslint-disable dot-notation */
import React, { useState } from 'react';
import { Pagination, Scrollbar } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { theme } from 'theme';

import OpinionSection, { OpinionSectionType } from 'components/molecules/opinion-section';
import { SliderType } from 'components/molecules/slider';
import SliderControls from 'components/molecules/slider-controls';

import { breakpoints, useScreenService } from 'hooks/use-screen-service';

import {
  StyledDotsTwo,
  StyledNavigation,
  StyledSwiper,
  StyledWrapper,
} from './slider-opinion-section.styles';

export type SliderOpinionSectionType = {
  joinUsOpinions: OpinionSectionType[];
};

const SliderOpinionSection = ({ joinUsOpinions }: SliderOpinionSectionType) => {
  const { isMobile } = useScreenService();
  const [snapGrid, setSnapGrid] = useState<number[]>([]);
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [status, setStatus] = useState<'beginning' | 'middle' | 'end'>('beginning');
  const [visibleSlides, setVisibleSlides] = useState<React.ReactNode[]>([]);

  const cardSliderOptions: SliderType['options'] = {
    slidesPerView: 1,
    spaceBetween: 16,
    watchSlidesProgress: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 1,
      },
      [breakpoints.md]: {
        slidesPerView: 1,
      },
    },
  };
  return (
    <StyledWrapper>
      <StyledSwiper
        modules={[Scrollbar, Pagination]}
        {...cardSliderOptions}
        onReachEnd={() => {
          setStatus('end');
        }}
        onReachBeginning={() => {
          setStatus('beginning');
        }}
        onSlideChange={(swiper) => {
          setSlideActiveIndex(swiper.activeIndex);
          if (visibleSlides) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
          if (swiper.activeIndex === 0) {
            setStatus('beginning');
          } else if (swiper.activeIndex >= joinUsOpinions.length - visibleSlides.length) {
            setStatus('end');
          } else {
            setStatus('middle');
          }
        }}
        onInit={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
        onResize={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
        onObserverUpdate={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
      >
        {joinUsOpinions
          ? joinUsOpinions.map((opinion, index) => (
              <SwiperSlide key={index}>
                <OpinionSection
                  margin={`${theme.gap.xl} auto`}
                  padding={theme.gap.xl}
                  maxWidth={theme.wrapper}
                  isDark
                  key={index}
                  {...opinion}
                />
                <StyledNavigation>
                  <SliderControls
                    {...{
                      slides: snapGrid,
                      activeIndex: slideActiveIndex,
                      status,
                    }}
                  />
                </StyledNavigation>
              </SwiperSlide>
            ))
          : null}
      </StyledSwiper>
      <StyledDotsTwo
        width={isMobile ? 0 : 2}
        height={isMobile ? 0 : 7}
        color={isMobile ? 'complementary3' : 'complementary3'}
      />
    </StyledWrapper>
  );
};

export default SliderOpinionSection;
