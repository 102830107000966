import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography/typography';
import WpImage from 'components/atoms/wp-image';

const backgroundSpace = 196;
const mobileBackgroundSpace = 85;

export const StyledWrapper = styled.div<DefaultType>`
  margin-top: ${`${mobileBackgroundSpace}px`};
  position: relative;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.gray0};
  ${breakpoint.md} {
    margin-top: ${`${backgroundSpace}px`};
  }
`;
export const StyledContent = styled.div<DefaultType>`
  margin: 0 auto;
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.wrapper};
  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.md} ${theme.gap.sm}`};

  ${breakpoint.md} {
    padding: ${({ theme }) => `0 0 ${theme.gap.xl} 0`};
  }
`;

export const StyledContentText = styled.div`
  max-width: ${({ theme }) => theme.wrapperXs};
  margin: 0 auto;
`;

export const StyledTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.mdl};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.xl};
  }
`;

export const StyledText = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
  p {
    margin-bottom: ${({ theme }) => theme.gap.sm};
  }
  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledWpImage = styled(WpImage)`
  margin-top: ${`-${mobileBackgroundSpace}px`};

  ${breakpoint.md} {
    margin-top: ${`-${backgroundSpace}px`};
  }
`;
