import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';
import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

import { ExtendedBaseCardType } from './base-card';

export const StyledTitle = styled(Typography)`
  grid-area: title;

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledIcon = styled(Icon)`
  grid-area: icon;
  margin-right: ${({ theme }) => theme.gap.md};
  height: 36px;
  width: 36px;

  ${breakpoint.md} {
    height: auto;
    width: auto;
  }
`;

export const StyledDescription = styled(Typography)`
  margin-block: ${({ theme }) => theme.gap.xs};
  grid-area: description;

  ${breakpoint.md} {
    margin-block: ${({ theme }) => theme.gap.sm};
    min-height: 72px;
  }
`;

export const StyledButton = styled(Button)`
  height: 20px;
  grid-area: button;

  &[data-variant='desktop'] {
    margin-top: ${({ theme }) => theme.gap.sm};

    ${breakpoint.md} {
      margin-top: 24px;
    }
  }

  span {
    text-decoration: underline;

    ${breakpoint.md} {
      display: none;
      text-decoration: none;
    }
  }
`;

const shouldUnsetBorder = ({
  idx,
  itemsLength,
}: Pick<ExtendedBaseCardType, 'idx' | 'itemsLength'>): boolean => {
  if (idx === undefined || itemsLength === undefined) {
    return false;
  }
  const isLastItem = idx === itemsLength - 1;
  const isSecondLastItem = idx === itemsLength - 2;
  const isEvenItems = itemsLength % 2 === 0;
  return (isEvenItems && (isLastItem || isSecondLastItem)) || (!isEvenItems && isLastItem);
};

export const StyledWrapper = styled.div<Pick<ExtendedBaseCardType, 'idx' | 'itemsLength'>>`
  padding-inline: 0;
  cursor: pointer;

  &[data-variant='services'] {
    &[data-type='both'] {
      display: grid;
      padding-bottom: ${({ theme }) => theme.gap.smd};
      grid-template-areas:
        'icon title title'
        'icon description description'
        'icon button button';
      border-bottom: unset;
      grid-template-columns: max-content 1fr 1fr;

      a {
        text-underline-offset: 4px;
      }

      ${breakpoint.md} {
        ${({ idx, theme, itemsLength }) =>
          shouldUnsetBorder({ idx, itemsLength })
            ? 'border-bottom: unset; background: unset !important'
            : `border-top: 1px solid ${theme.colors.gray40}`};
        grid-template-areas:
          'icon'
          'title'
          'description'
          'button';
        padding-block: ${({ theme }) => theme.gap.md};
        grid-template-columns: unset;
        &:after {
          ${({ idx, itemsLength }) =>
            shouldUnsetBorder({ idx, itemsLength }) ? 'content: unset !important' : ''};
        }

        a {
          text-underline-offset: unset;
        }
      }
    }

    &[data-type='desktop'] {
      ${({ idx, theme }) => (idx === 0 ? '' : `border-bottom:1px solid ${theme.colors.gray40}`)};
      display: flex;
      border-bottom: unset;
      align-items: center;
      padding-block: ${({ theme }) => theme.gap.sm};

      ${breakpoint.md} {
        display: grid;

        ${({ idx, theme, itemsLength }) =>
          shouldUnsetBorder({ idx, itemsLength })
            ? 'border-bottom: unset; background: unset !important'
            : `border-bottom: 1px solid ${theme.colors.gray40}`};
        grid-template-areas:
          'icon'
          'title'
          'description'
          'button';

        &:after {
          ${({ idx, itemsLength }) =>
            shouldUnsetBorder({ idx, itemsLength }) ? 'content: unset !important' : ''};
        }

        padding-block: ${({ theme }) => theme.gap.md};
        padding-top: ${({ idx, theme }) => (idx === 0 || idx === 1 ? 0 : theme.gap.md)};
      }

      ${StyledButton} {
        margin-left: auto;

        ${breakpoint.md} {
          margin-left: 0;
        }

        span {
          display: none;
        }
      }

      ${StyledIcon} {
        margin-right: 24px;
      }

      ${StyledTitle} {
        margin-right: ${({ theme }) => theme.gap.sm};
      }
    }

    ${breakpoint.md} {
      padding-block: ${({ theme }) => theme.gap.md};
    }

    &:hover {
      ${breakpoint.md} {
        border-color: ${({ theme }) => theme.colors.primary50};
      }

      .card-with-icon__button span {
        ${breakpoint.md} {
          display: block;
        }
      }
    }
  }

  &[data-variant='industries'] {
    display: grid;
    grid-template-areas:
      'icon'
      'title'
      'button';
    grid-auto-rows: minmax(min-content, max-content);
    align-content: end;
    padding: 16px;
    background-color: rgba(127, 181, 225, 1);
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    height: 192px;
    width: 100%;
    position: relative;
    * {
      color: white;
    }

    ${breakpoint.md} {
      padding: 24px;
      height: 255px;
    }

    ${StyledTitle} {
      margin-top: 18px;
    }

    ${StyledButton} {
      margin-top: 24px;
    }

    .card-with-icon__button {
      background: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary50};

      .card-with-icon__button {
        span {
          display: block;
          animation: move-text-cart 0.2s linear forwards;
        }

        svg {
          animation: move-svg 0.2s linear forwards;
        }
      }
    }
  }
`;

export const StyledDarkWrapper = styled(StyledWrapper)`
  &[data-variant='industries'] {
    display: grid;
    grid-template-areas:
      'icon'
      'title'
      'button';
    grid-auto-rows: minmax(min-content, max-content);
    align-content: end;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.complementary1};
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-out;

    height: 192px;
    width: 100%;

    * {
      color: white;
    }

    ${breakpoint.md} {
      padding: 24px;
      height: 255px;
    }

    ${StyledTitle} {
      margin-top: 18px;
    }

    ${StyledButton} {
      margin-top: 24px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary50};

      .card-with-icon__button span {
        ${breakpoint.md} {
          display: block;
        }
      }
    }
  }
`;
