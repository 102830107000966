import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  max-width: ${({ maxWidth }) => maxWidth || '286px'};
  margin-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledText = styled(Typography)`
  margin-top: ${({ theme }) => theme.gap.sm};
`;
