import styled from 'styled-components';

import Typography from 'components/atoms/typography';

export const StyledBadge = styled.div<{ typeOrange: boolean }>`
  background-color: ${({ theme, typeOrange }) =>
    typeOrange ? theme.colors.primary50 : theme.colors.complementary1};
  width: fit-content;
  padding-inline: 10px;
  height: 21px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLabel = styled(Typography)``;
