import styled from 'styled-components';
import { breakpoint } from 'theme';

import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

const widthCard = 'calc(50% - 8px)';

export const StyledCard = styled.div<{ maxWidth?: string }>`
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.wrapperXxl};
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: ${({ theme }) => theme.gap.sm};
  padding: ${({ theme }) => `${theme.gap.sm}`};

  ${breakpoint.md} {
    margin-top: ${({ theme }) => theme.gap.sm};
    padding: ${({ theme }) => `${theme.gap.smd}`};
    width: ${widthCard};
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.gap.smd};
`;
export const StyledTypography = styled(Typography)`
  width: 84%;
  ${breakpoint.md} {
    width: 100%;
  }
`;
