import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.transparent};
  padding: ${({ theme, mobilePadding }) => mobilePadding || theme.gap.sm};
  display: flex;
  justify-content: center;

  ${breakpoint.md} {
    margin: ${({ margin }) => margin};
    padding: ${({ theme, padding }) => padding || `${theme.gap.xl} 0`};
  }
`;

export const StyldSubTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.gap.xs};
  color: ${({ theme }) => theme.colors.gray40};

  ${breakpoint.md} {
    margin-bottom: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledContent = styled.div<{ maxWidth?: string }>`
  max-width: ${({ theme, maxWidth }) => maxWidth || theme.wrapper};
  width: 100%;
`;

export const StyledTextSection = styled.div<{ textColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  // gap: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    flex-direction: ${({ textColumn }) => (textColumn ? 'column' : 'row')};
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledTitle = styled(Typography)<{ sub?: boolean; textColumn?: boolean }>`
  margin-top: ${({ theme, sub }) => sub && theme.gap.sm};
  margin-bottom: ${({ theme }) => theme.gap.xs};

  strong {
    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }

  ${breakpoint.md} {
    margin-top: ${({ theme, sub }) => sub && theme.gap.md};
    width: ${({ textColumn }) => !textColumn && '53%'};
    margin-right: ${({ theme }) => theme.gap.lg};
    margin-bottom: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledDescription = styled(Typography)<{ textColumn?: boolean }>`
  ${breakpoint.md} {
    width: ${({ textColumn }) => (textColumn ? '100%' : '60%')};
  }
`;

export const StyledCardSection = styled.div<{ tiles?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
    flex-wrap: ${({ tiles }) => tiles && 'wrap'};
  }
`;
