import styled from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<{ isSlider?: boolean }>`
  padding: ${({ theme }) => theme.gap.sm};
  min-height: 128px;
  width: 100%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: ${({ isSlider }) => (isSlider ? '192px' : '')};

  a {
    height: 100%;
  }

  ${breakpoint.md} {
    padding: 24px;
    height: 284px;
    max-width: 359px;
    border: 2px solid white;

    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.primary50};
      box-shadow: 0px 6px 20px -2px rgba(26, 26, 26, 0.14), 0px 4px 6px rgba(26, 26, 26, 0.06);
    }
  }
`;

export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)`
  padding-block: ${({ theme }) => `${theme.gap.xs} ${theme.gap.sm}`};
`;

export const StyledSubHeading = styled(Typography)`
  max-width: 250px;

  &[data-variant='body3'] {
    font-weight: 600;
  }
`;

export const StyledLocalization = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.xxs};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.xs};
  }
`;

export const StyledDate = styled(Typography)`
  margin-top: auto;
  &[data-variant='body3'] {
    font-weight: 600;
  }
`;

export const WrapperContentOfferCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const WrapperLocationContractType = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: self-start;
  margin-bottom: 8px;

  div:not(:first-child)::before {
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50px;
    margin-inline: 8px;
    color: #838791;
    background: #838791;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  ${breakpoint.md} {
    flex-direction: column;

    div:not(:first-child)::before {
      content: unset;
    }
  }
`;
