import React from 'react';
import { useSwiper } from 'swiper/react';

import Button, { ButtonType } from 'components/atoms/button';

import { StyledNavigation, StyledWrapper } from './slider-controls.styles';

export type SliderControlsType = {
  slides: number[];
  linkButton?: ButtonType;
  status?: 'beginning' | 'end' | 'middle';
};

const SliderControls = ({ slides = [], linkButton, status }: SliderControlsType) => {
  const swiper = useSwiper();

  const isBeginning = status === 'beginning';
  const isEnd = status === 'end';

  if (!swiper || slides.length <= 1) return null;

  return (
    <StyledWrapper className="slider-controls__wrapper">
      {linkButton ? (
        <Button {...linkButton} variant="underline" className="slider-controls__button" />
      ) : null}
      <StyledNavigation className="slider-controls">
        <Button
          icon={{ name: 'arrow-left-big' }}
          variant="slider"
          className="slider-controls__nav"
          disabled={isBeginning}
          onClick={() => {
            if (!isBeginning) {
              swiper.slidePrev();
            }
          }}
        />
        <Button
          icon={{ name: 'arrow-right-big' }}
          variant="slider"
          disabled={isEnd}
          className="slider-controls__nav"
          onClick={() => {
            if (!isEnd) {
              swiper.slideNext();
            }
          }}
        />
      </StyledNavigation>
    </StyledWrapper>
  );
};

export default SliderControls;
