import styled from 'styled-components';
import { breakpoint } from 'theme';

export const StyledWrapper = styled.div`
  order: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledNavigation = styled.div`
  display: none;
  gap: 8px;

  ${breakpoint.md} {
    display: flex;
    align-items: flex-end;
  }
`;
