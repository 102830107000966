import React from 'react';
import { navigate } from 'gatsby';

import Badge, { BadgeType } from 'components/atoms/badge';
import Link from 'components/atoms/link';
import Typography from 'components/atoms/typography';

import useCurrentLanguage from '../../../hooks/use-current-language';

import {
  StyledDate,
  StyledLocalization,
  StyledRow,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
  WrapperContentOfferCard,
  WrapperLocationContractType,
} from './job-offer-card.styles';

export type JobOfferCardType = {
  title: string;
  date: string;
  uri: string;
  isSlider?: boolean;
  offersData: {
    consultant: string;
    category?: string;
    voivodship: string;
    city: string;
    contracttypes: string;
    salaryfrom: number;
    salaryto: number;
  };
  badge?: BadgeType;
};

const JobOfferCard = ({
  offersData,
  title,
  date,
  uri,
  badge,
  isSlider = false,
}: JobOfferCardType) => {
  const offerDate = new Date(date);
  const currentDate = new Date();
  const daysDifference = Math.floor(
    (currentDate.getTime() - offerDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const showBadge = daysDifference <= 7;
  const formattedContractTypes = Array.isArray(offersData.contracttypes)
    ? offersData.contracttypes.join(', ')
    : offersData.contracttypes || '';
  const currentLanguage = useCurrentLanguage();
  const publishedText = currentLanguage === 'PL' ? 'opublikowano' : 'published';
  const daysAgoText = currentLanguage === 'PL' ? 'dni temu' : 'days ago';
  const newBadgeText = currentLanguage === 'PL' ? 'NOWA' : 'NEW';
  const newUri = currentLanguage === 'PL' ? uri : `/en${uri}`;

  const formatDateDifference = () => {
    switch (daysDifference) {
      case 0:
        return currentLanguage === 'PL' ? 'dziś' : 'today';
      case 1:
        return currentLanguage === 'PL' ? 'wczoraj' : 'yesterday';
      default:
        if (daysDifference > 35) {
          return '';
        }
        return `${daysDifference} ${daysAgoText}`;
    }
  };
  return (
    <StyledWrapper
      isSlider={isSlider}
      data-link={newUri || ''}
      onClick={() => navigate(newUri || '')}
    >
      <WrapperContentOfferCard>
        <div>
          {showBadge || badge ? (
            <StyledRow>
              <StyledSubHeading variant="body3" color="gray60" html={offersData.category} />
              <Badge label={newBadgeText} />
            </StyledRow>
          ) : (
            <StyledSubHeading variant="body3" color="gray60" html={offersData.category} />
          )}
          {title ? (
            <Link internal={{ uri: newUri }} ariaLabel={title}>
              <StyledTitle variant="title3" color="primary50" component="h3">
                {title}
              </StyledTitle>
            </Link>
          ) : null}
          <WrapperLocationContractType>
            <StyledLocalization
              html={`${offersData.city ? `${offersData.city}, ` : ''}${offersData.voivodship}`}
              variant="title4"
              color="gray80"
            />
            <Typography html={formattedContractTypes} variant="title4" color="gray80" />
          </WrapperLocationContractType>
        </div>

        <div>
          <StyledDate variant="body3" color="gray60">
            {daysDifference > 35 ? '' : publishedText} {formatDateDifference()}
          </StyledDate>
        </div>
      </WrapperContentOfferCard>
    </StyledWrapper>
  );
};

export default JobOfferCard;
