import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';
import { JobOffersType } from 'types/home-page';
import { AllJobOfferType } from 'types/job-offers';

import Layout from 'components/templates/layout';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image';
import JobOffersSlider from 'components/organisms/job-offers-slider';
import MoreInformationSection, {
  MoreInformationSectionType,
} from 'components/organisms/more-information-section';
import OurValuesSection, { OurValuesSectionType } from 'components/organisms/our-values-section';
import RecruitmentProcessSteps, {
  RecruitmentProcessStepsType,
} from 'components/organisms/recruitment-process-steps';
import SliderOpinionSection from 'components/organisms/slider-opinion-section';
import StructureSection, { StructureSectionType } from 'components/organisms/structure-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCardTitles from 'components/molecules/banner-card-titles';
import { OpinionSectionType } from 'components/molecules/opinion-section';
import SimpleCard from 'components/molecules/simple-card/simple-card';
import { TeamListCardType } from 'components/molecules/team-list-card';
import Breadcrumb from 'components/atoms/breadcrumb';
import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledBaseCard,
  StyledContentBaseCardListin,
} from '../components/organisms/base-card-listing/base-card-listing.styles';

type BannerJoinUs = {
  imagesList: { image: WpImageType }[];
} & BannerPrimaryType;

type JoinUsOurTeamsType = {
  heading: string;
  subTitle: string;
  cardList: TeamListCardType[];
};

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      moreInformationSection: MoreInformationSectionType;
    };
  };
  allJobOfferType: AllJobOfferType;
  aboutJoinUsPage: {
    template: {
      aboutJoinUsData: {
        bannerJoinUs: BannerJoinUs;
        hrkStructure: StructureSectionType;
        workCulture: TextBannerCardType;
        joinUsOurTeams: JoinUsOurTeamsType;
        joinUsOpinions: OpinionSectionType[];
        joinUsBenefits: TextBannerCardType;
        joinUsProcess: RecruitmentProcessStepsType;
        joinUsOurValues: OurValuesSectionType;
        jobOffersSection: JobOffersType;
      };
    };
  };
};

const AboutJoinUsPage = ({
  pageContext,
  data: {
    aboutJoinUsPage: {
      template: {
        aboutJoinUsData: {
          bannerJoinUs,
          workCulture,
          hrkStructure,
          joinUsOurValues,
          joinUsOurTeams,
          joinUsOpinions,
          joinUsBenefits,
          joinUsProcess,
          jobOffersSection,
        },
      },
    },
    allJobOfferType,
    commonComponents: {
      commonComponentsData: { moreInformationSection },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={bannerJoinUs.heading}
      subtitleTop={bannerJoinUs.text}
      imagesList={bannerJoinUs.imagesList}
      positionTitle="center"
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection
      {...workCulture}
      variantHeading="title2"
      maxWidth={theme.wrapperXs}
      textColumn
      tiles
    >
      {workCulture ? workCulture.cardList.map((card) => <SimpleCard {...card} />) : null}
    </TextBannerCardSection>
    <StructureSection {...hrkStructure} />
    <OurValuesSection {...joinUsOurValues} />
    <TextBannerCardSection
      heading={joinUsOurTeams.heading}
      subTitle={joinUsOurTeams.subTitle}
      bgColor={theme.colors.gray0}
      variantHeading="title8"
      maxWidth={theme.wrapper}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm} ${theme.gap.sm}`}
      tiles
      textColumn
      cardList={[]}
    >
      <StyledContentBaseCardListin smallMarginContent>
        {joinUsOurTeams
          ? joinUsOurTeams.cardList.map((card, idx) => (
              <StyledBaseCard
                icon={card.cardPrimary.icon}
                linkButton={card.cardPrimary.linkButton}
                heading={card.cardPrimary.heading}
                description={card.cardPrimary.text}
                variant="services"
                showDescription="desktop"
                key={card.cardPrimary.heading}
                idx={idx}
                itemsLength={joinUsOurTeams.cardList.length}
                className="listing-base-card"
              />
            ))
          : null}
      </StyledContentBaseCardListin>
    </TextBannerCardSection>
    <SliderOpinionSection joinUsOpinions={joinUsOpinions} />
    <TextBannerCardSection
      {...joinUsBenefits}
      bgColor={theme.colors.gray0}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      tiles
    >
      {joinUsBenefits
        ? joinUsBenefits.cardList.map((card, index) => <BannerCardTitles {...card} key={index} />)
        : null}
    </TextBannerCardSection>
    <RecruitmentProcessSteps
      {...joinUsProcess}
      padding={`${theme.gap.xl} 0`}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
    />
    <JobOffersSlider
      heading={jobOffersSection.heading}
      subHeading={jobOffersSection.subHeading}
      linkButton={jobOffersSection.linkButton}
      cards={allJobOfferType.edges}
    />
    <MoreInformationSection {...moreInformationSection} />
  </Layout>
);

export const query = graphql`
  query AboutJoinUsPage($id: String, $commonComponentsSlug: String!) {
    aboutJoinUsPage: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_AboutJoinUs {
          aboutJoinUsData {
            fieldGroupName
            bannerJoinUs {
              heading
              text
              imagesList {
                image {
                  ...WpImageFragment
                }
              }
            }
            workCulture {
              heading
              text: description
              cardList: informationList {
                heading
                text: description
              }
            }
            hrkStructure {
              heading
              text: description
              image {
                ...WpImageFragment
              }
            }
            joinUsOurValues {
              heading
              text: description
              valuesList {
                text
              }
            }
            joinUsOurTeams {
              heading
              subTitle: subHeading
              cardList: teamList {
                cardPrimary {
                  heading
                  text
                  linkButton {
                    label
                    link {
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      ariaLabel
                      external {
                        target
                        url
                      }
                    }
                    icon {
                      name
                    }
                  }
                  icon {
                    name
                  }
                }
              }
            }
            joinUsOpinions {
              cite: text
              fullName: author
              position
              image {
                ...WpImageFragment
              }
            }
            joinUsBenefits {
              heading
              subTitle
              text: description
              cardList: benefitsList {
                heading: text
                icon {
                  name
                }
              }
            }
            joinUsProcess {
              heading
              subTitle
              text
              processSteps {
                heading
                text
              }
            }
            jobOffersSection {
              heading
              subTitle
              linkButton {
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    target
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offerTypes: { nodes: { elemMatch: { name: { eq: "HRK" } } } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        moreInformationSection {
          heading
          cardList {
            text
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  id
                  uri
                }
              }
              external {
                target
                url
              }
            }
            icon {
              name
            }
          }
        }
      }
    }
  }
`;

export default AboutJoinUsPage;
