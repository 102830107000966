import styled from 'styled-components';
import { breakpoint } from 'theme';

import BaseCard from 'components/molecules/base-card';
import Typography from 'components/atoms/typography';

import { StyledTypeCartContent } from './base-card-listing';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  margin-inline: auto;
  padding-inline: ${({ theme }) => theme.gap.sm};
  padding-block: ${({ theme }) => `36px ${theme.gap.xxs} `};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => `${theme.gap.xl} 40px`};
  }

  ${breakpoint.lg} {
    padding-inline: 0;
  }

  &[data-variant='secondary'] {
    .listing-title {
      padding-block: ${({ theme }) => `${theme.gap.xs} 0`};

      ${breakpoint.md} {
        padding-block: ${({ theme }) => `${theme.gap.sm} 0`};
      }
    }
  }
`;

export const StyledBaseCard = styled(BaseCard)`
  &:hover {
    .card-with-icon__button {
      span {
        display: block;
        animation: move-text-cart 0.3s linear forwards;
      }

      svg {
        animation: move-svg 0.3s linear forwards;
      }
    }

    .card-with-icon__button span {
      ${breakpoint.md} {
        display: block;
      }
    }
  }

  ${breakpoint.md} {
    padding-bottom: 37px;
  }
`;

export const StyledContentBaseCardListin = styled.div<StyledTypeCartContent>`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr;
  padding-block: 8px 20px;

  ${breakpoint.md} {
    margin-top: ${({ theme, smallMarginContent }) =>
      smallMarginContent ? theme.gap.md : theme.gap.xl};
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    column-gap: ${({ theme }) => theme.gap.md};
    padding-block: 0;

    &[data-variant='secondary'] {
      grid-template-columns: repeat(3, 1fr);
      gap: 32px 30px;

      .listing-base-card {
        border: none;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    &[data-variant='primary'] {
      ${StyledBaseCard} {
        &[data-variant='services'] {
          border: unset !important;
          position: relative;
          height: 100%;
          margin-bottom: 0px;
          background: linear-gradient(to right, #fb6121 0%, #fb6121 100%);
          background-repeat: no-repeat;
          background-size: 0 2px;
          background-position: 0 100%;
          transition: background-size 0.4s;

          &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: 1px;
            width: 100%;
            height: 1px;
            z-index: -1;
            background: #bec0c5;
          }
        }

        &:hover {
          background-image: linear-gradient(to right, #fb6121, #fb6121);
          background-size: 100% 2px;
        }
      }
    }
  }
  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledSubTitle = styled(Typography)``;

export const StyledTitle = styled(Typography)`
  width: 100%;
  padding-block: ${({ theme }) => `${theme.gap.sm} ${theme.gap.xs}`};
`;
