import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import { breakpoint } from 'theme';

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .swiper-slide {
    &:last-child {
      padding-right: ${({ theme }) => theme.gap.sm};
    }
  }
  
  ${breakpoint.md} {
    .swiper-slide {
      &:last-child {
        padding-right: unset; {
      }
      }
    }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
