/* eslint-disable dot-notation */
import React, { useState } from 'react';
import { Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import SliderControls from 'components/molecules/slider-controls';
import { ButtonType } from 'components/atoms/button';

import { useScreenService } from 'hooks/use-screen-service';

import { StyledSwiper, StyledWrapper } from './slider.styles';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';

export type SliderType = {
  slides: React.ReactNode[];
  options?: SwiperOptions;
  variant?: SliderVariantType;
  linkButton?: ButtonType;
  className?: string;
};

export type SliderVariantType = 'default' | 'primary' | 'secondary' | 'tertiary';

const Slider = ({
  variant = 'default',
  slides = [],
  options = {},
  className,
  linkButton,
}: SliderType) => {
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [status, setStatus] = useState<'beginning' | 'middle' | 'end'>('beginning');
  const [visibleSlides, setVisibleSlides] = useState<React.ReactNode[]>([]);
  const { isMobile } = useScreenService();

  const [snapGrid, setSnapGrid] = useState<number[]>([]);

  if (!slides?.length) return null;

  const defaultSlider = (
    <StyledSwiper
      {...options}
      onSlideChange={(swiper) => {
        setSlideActiveIndex(swiper.activeIndex);
        if (visibleSlides) {
          setVisibleSlides(swiper['visibleSlidesIndexes']);
        }

        if (isMobile) {
          if (swiper.activeIndex === 0) {
            // eslint-disable-next-line no-param-reassign
            swiper.params.slidesPerView = options.slidesPerView;
          } else if (swiper.activeIndex === slides.length - 1) {
            // eslint-disable-next-line no-param-reassign
            swiper.params.slidesPerView = 1;
          } else {
            // eslint-disable-next-line no-param-reassign
            swiper.params.slidesPerView = options.slidesPerView || 'auto';
          }
        }

        swiper.update();
      }}
      {...{ className }}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </StyledSwiper>
  );

  const sliderWithControls = (
    <StyledWrapper {...{ className }}>
      <StyledSwiper
        modules={[Scrollbar, Pagination]}
        data-variant={variant}
        {...options}
        autoHeight
        observer
        onReachEnd={() => {
          setStatus('end');
        }}
        onReachBeginning={() => {
          setStatus('beginning');
        }}
        onSlideChange={(swiper) => {
          setSlideActiveIndex(swiper.activeIndex);
          if (visibleSlides) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
          if (swiper.activeIndex === 0) {
            setStatus('beginning');
          } else if (swiper.activeIndex >= slides.length - visibleSlides.length) {
            setStatus('end');
          } else {
            setStatus('middle');
          }
        }}
        onInit={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
        onResize={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
        onObserverUpdate={(swiper) => {
          if (swiper?.snapGrid) {
            setSnapGrid(swiper.snapGrid);
          }
          if (swiper['visibleSlidesIndexes']) {
            setVisibleSlides(swiper['visibleSlidesIndexes']);
          }
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
        <SliderControls
          {...{
            slides: snapGrid,
            activeIndex: slideActiveIndex,
            variant,
            linkButton,
            status,
          }}
        />
      </StyledSwiper>
    </StyledWrapper>
  );

  return variant === 'primary' || variant === 'tertiary' ? sliderWithControls : defaultSlider;
};

export default Slider;
