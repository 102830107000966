import React from 'react';

import { StyledBadge, StyledLabel } from './badge.styles';

export type BadgeType = {
  label: string;
  typeOrange?: boolean;
};

const Badge = ({ label, typeOrange = false }: BadgeType) => (
  <StyledBadge typeOrange={typeOrange}>
    <StyledLabel variant="title5" color="white" component="p">
      {label}
    </StyledLabel>
  </StyledBadge>
);

export default Badge;
