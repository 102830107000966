import React from 'react';
import { DefaultType } from 'types/default';

import { WpImageType } from 'components/atoms/wp-image';

import {
  StyledContent,
  StyledContentText,
  StyledText,
  StyledTitle,
  StyledWpImage,
  StyledWrapper,
} from './structure-section.styles';

export type StructureSectionType = {
  heading: string;
  text: string;
  image: WpImageType;
} & DefaultType;

const StructureSection = ({
  heading,
  text,
  image,
  margin,
  padding,
  maxWidth,
}: StructureSectionType) => (
  <StyledWrapper margin={margin} padding={padding} maxWidth={maxWidth}>
    <StyledContent>
      <StyledWpImage {...image} />
      <StyledContentText>
        <StyledTitle variant="title2" component="h2" html={heading} />
        <StyledText variant="body1" html={text} />
      </StyledContentText>
    </StyledContent>
  </StyledWrapper>
);

export default StructureSection;
