import React from 'react';
import { navigate } from 'gatsby';

import { ButtonType } from 'components/atoms/button';
import { IconType } from 'components/atoms/icon';
import Link from 'components/atoms/link';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledButton,
  StyledDarkWrapper,
  StyledDescription,
  StyledIcon,
  StyledTitle,
  StyledWrapper,
} from './base-card.styles';

export type BaseCardVariant = 'services' | 'industries';

export type BGDark = boolean;

export type BaseCardType = {
  heading: string;
  linkButton: ButtonType;
  icon?: IconType;
  description?: string;
  variant?: BaseCardVariant;
  showDescription?: 'both' | 'desktop';
  className?: string;
  idx?: number;
  itemsLength?: number;
  bgDark?: BGDark;
};

export type ExtendedBaseCardType = BaseCardType & {
  itemsLength?: number;
};
const BaseCard = ({
  icon,
  heading,
  description,
  showDescription = 'both',
  linkButton,
  className,
  idx,
  itemsLength,
  variant = 'services',
  bgDark,
}: BaseCardType) => {
  const { isMobile, isMdUp } = useScreenService();
  const renderDescription = showDescription === 'both' || (showDescription === 'desktop' && isMdUp);
  const isServicesVariant = variant === 'services';
  let content;
  const link = (linkButton.link?.internal as { uri: string } | undefined)?.uri.toString();
  if (bgDark) {
    content = (
      <StyledDarkWrapper
        data-variant={variant}
        data-type={showDescription}
        className={className}
        idx={idx}
        itemsLength={itemsLength}
        data-link={link || ''}
        onClick={() => link && navigate(link)}
      >
        {icon ? <StyledIcon {...icon} /> : null}
        {link ? (
          <Link internal={{ uri: link }} ariaLabel={heading}>
            <StyledTitle variant="title3" component="h3">
              {heading}
            </StyledTitle>
          </Link>
        ) : (
          <StyledTitle variant="title3" component="h3">
            {heading}
          </StyledTitle>
        )}
        {renderDescription && description ? (
          <StyledDescription variant={isServicesVariant ? 'body1' : 'body3'} html={description} />
        ) : null}
        <StyledButton
          {...linkButton}
          icon={{ name: isMobile && isServicesVariant ? 'arrow-right-big' : 'arrow-right' }}
          width="max-content"
          variant="reversed-underline"
          className="card-with-icon__button"
        />
      </StyledDarkWrapper>
    );
  } else {
    content = (
      <StyledWrapper
        data-variant={variant}
        data-type={showDescription}
        className={className}
        idx={idx}
        itemsLength={itemsLength}
        data-link={link || ''}
        onClick={() => link && navigate(link)}
      >
        {icon ? <StyledIcon {...icon} /> : null}
        {link ? (
          <Link internal={{ uri: link }} ariaLabel={heading}>
            <StyledTitle variant="title3" component="h3">
              {heading}
            </StyledTitle>
          </Link>
        ) : (
          <StyledTitle variant="title3" component="h3">
            {heading}
          </StyledTitle>
        )}
        {renderDescription && description ? (
          <StyledDescription
            variant={isServicesVariant ? 'body1' : 'body3'}
            data-link={link || ''}
            onClick={() => navigate(link || '')}
            component={isServicesVariant ? 'p' : 'h3'}
            html={description}
          />
        ) : null}

        <StyledButton
          {...linkButton}
          icon={{ name: isMobile && isServicesVariant ? 'arrow-right-big' : 'arrow-right' }}
          width="max-content"
          variant="reversed-underline"
          className="card-with-icon__button"
        />
      </StyledWrapper>
    );
  }

  return <div>{content}</div>;
};

export default BaseCard;
