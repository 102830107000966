import React from 'react';

import { IconType } from 'components/atoms/icon';

import { StyledCard, StyledIcon, StyledTypography } from './banner-card-titles.styles';

export type BannerCardTitlesType = {
  heading: string;
  icon?: IconType;
};

const BannerCardTitles = ({ heading, icon }: BannerCardTitlesType) => (
  <StyledCard>
    {icon && <StyledIcon {...icon} />}
    <StyledTypography variant="title3" component="h3" html={heading} />
  </StyledCard>
);

export default BannerCardTitles;
