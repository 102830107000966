import React from 'react';
import { DefaultType } from 'types/default';

import Slider, { SliderType } from 'components/molecules/slider';
import { ButtonType } from 'components/atoms/button';

import { breakpoints } from 'hooks/use-screen-service';

import { JobOfferCartType } from '../../../types/job-offers';
import JobOfferCard from '../../molecules/job-offer-card';

import {
  StyledButton,
  StyledContent,
  StyledContentWrapper,
  StyledRow,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './job-offers-slider.styles';

export type JobOffersSliderType = {
  heading: string;
  linkButton?: ButtonType;
  cards: JobOfferCartType[];
  subHeading?: string;
} & DefaultType;

const JobOffersSlider = ({
  heading,
  subHeading,
  linkButton,
  cards,
  padding,
  bgColor,
}: JobOffersSliderType) => {
  const cardsSlides = cards.slice(0, 3).map((card) => <JobOfferCard isSlider {...card.node} />);

  const cardSliderOptions: SliderType['options'] = {
    slidesPerView: 1.2199,
    spaceBetween: 8,
    watchSlidesProgress: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
  };

  return (
    <StyledWrapper padding={padding} bgColor={bgColor}>
      <StyledContent>
        <StyledContentWrapper>
          <StyledTitle variant="title2" color="gray100" component="h2" html={heading} />
          <StyledRow>
            {subHeading ? (
              <StyledSubHeading variant="body1" color="gray80" html={subHeading} component="h2" />
            ) : null}
            <StyledButton {...linkButton} variant="underline" />
          </StyledRow>
        </StyledContentWrapper>

        <Slider
          {...{
            slides: cardsSlides,
            options: cardSliderOptions,
            variant: 'secondary',
          }}
        />
      </StyledContent>
    </StyledWrapper>
  );
};

export default JobOffersSlider;
