import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';

export const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  display: flex;
  flex-direction: column;
  align-items: center;

  .swiper-slide {
    &:last-child {
      padding-right: ${({ theme }) => theme.gap.sm};
    }
  }
  ${breakpoint.md} {
    .swiper-slide {
      &:last-child {
        padding-right: unset;
         {
        }
      }
    }
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.wrapper};
  margin: auto;
  position: relative;
  padding-inline: ${({ theme }) => theme.gap.sm};
  padding-block: ${({ theme }) => `4px ${theme.gap.mdl}`};

  ${breakpoint.md} {
    padding-inline: 0;
    padding-block: 0;
  }
`;

export const StyledNavigation = styled.div`
  .slider-controls {
    display: flex;
  }

  [data-variant='slider']:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray60};
    border-color: ${({ theme }) => theme.colors.gray40};
    &:hover {
      border-color: ${({ theme }) => theme.colors.gray40};
    }
  }

  [data-variant='slider'] {
    color: ${({ theme }) => theme.colors.gray100};
    border-color: ${({ theme }) => theme.colors.gray40};
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.gray100};
    }
  }

  ${breakpoint.lg} {
    position: absolute;
    bottom: 88px;
  }
`;
export const StyledDotsTwo = styled(DotsGrid)`
  z-index: 10;
  display: none;

  ${breakpoint.lg} {
    display: block;
    top: 72px;
    right: -90px;
  }
`;
