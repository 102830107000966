import React from 'react';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

import { StyledText, StyledWrapper } from './simple-card.styles';

export type SimpleCardType = {
  heading?: string;
  text?: string;
} & DefaultType;

const SimpleCard = ({ heading, text, maxWidth }: SimpleCardType) => (
  <StyledWrapper maxWidth={maxWidth}>
    {heading && <Typography variant="title3" component="h3" html={heading} />}
    {text && <StyledText variant="body1" html={text} />}
  </StyledWrapper>
);

export default SimpleCard;
