import React from 'react';
import { DefaultType } from 'types/default';

import { BannerCardType } from 'components/molecules/banner-card';
import { TypographyVariantType } from 'components/atoms/typography';

import {
  StyldSubTitle,
  StyledCardSection,
  StyledContent,
  StyledDescription,
  StyledTextSection,
  StyledTitle,
  StyledWrapper,
} from './text-banner-card-section.styles';

export type optionalBannerCardType = {
  subTitle?: string;
  text?: string;
  extraTitle?: string;
  extraText?: string;
  textColumn?: boolean;
  tiles?: boolean;
  variantHeading?: TypographyVariantType;
  variantExtraTitle?: TypographyVariantType;
};

export type TextBannerCardType = {
  // eslint-disable-next-line react/no-unused-prop-types
  cardList: BannerCardType[]; // use on page files
  text?: string;
} & Record<'heading', string>;

export type propsTextBannerCardType = {
  children?: React.ReactNode;
} & TextBannerCardType &
  DefaultType &
  optionalBannerCardType;

const TextBannerCardSection = ({
  children,
  subTitle,
  heading,
  text,
  extraTitle,
  extraText,
  tiles,
  textColumn,
  maxWidth,
  margin,
  padding,
  mobilePadding,
  bgColor,
  variantHeading,
  variantExtraTitle,
}: propsTextBannerCardType) => {
  const extraTextBlock = (
    <>
      {extraTitle && (
        <StyledTitle
          textColumn={textColumn}
          html={extraTitle}
          variant={variantExtraTitle || 'title2'}
          sub
        />
      )}
      {extraText && <StyledDescription html={extraText} variant="body1" />}
    </>
  );

  return (
    <StyledWrapper
      margin={margin}
      padding={padding}
      mobilePadding={mobilePadding}
      bgColor={bgColor}
    >
      <StyledContent maxWidth={maxWidth}>
        {subTitle && <StyldSubTitle html={subTitle} variant="title4" />}
        <StyledTextSection textColumn={textColumn}>
          <StyledTitle
            html={heading}
            variant={variantHeading || 'title2'}
            textColumn={textColumn}
          />
          <StyledDescription variant="body1" html={text} textColumn={textColumn} />
          {extraTextBlock}
        </StyledTextSection>
        <StyledCardSection tiles={tiles}>{children}</StyledCardSection>
      </StyledContent>
    </StyledWrapper>
  );
};

export default TextBannerCardSection;
