import React from 'react';
import { DefaultType } from 'types/default';

import {
  StyledContainer,
  StyledListItemValue,
  StyledListValues,
  StyledTextIntent,
  StyledWrapper,
} from './our-values-section.styles';

export type OurValuesSectionType = {
  valuesList: {
    text: string;
  }[];
} & DefaultType &
  Record<'heading' | 'text', string>;

const OurValuesSection = ({ text, heading, valuesList, margin, bgColor }: OurValuesSectionType) => (
  <StyledWrapper bgColor={bgColor} margin={margin}>
    <StyledContainer>
      <StyledTextIntent color="gray100" variant="title2" component="h2" html={heading} />
      <StyledTextIntent color="gray100" variant="body1" html={text} />
      <StyledListValues>
        {valuesList
          ? valuesList.map((item) => (
              <StyledListItemValue>
                <p>{item.text}</p>
              </StyledListItemValue>
            ))
          : null}
      </StyledListValues>
    </StyledContainer>
  </StyledWrapper>
);

export default OurValuesSection;
