import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  display: flex;
  justify-content: center;
  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.md} ${theme.gap.sm}`};

  ${breakpoint.md} {
    padding: ${({ theme, padding }) => padding || `${theme.gap.xl} 0`};
  }
`;

export const StyledContent = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
  flex-direction: column;

  ${breakpoint.md} {
    flex-direction: row;
    gap: 30px;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled(Typography)`
  strong {
    text-decoration-color: transparent;

    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`;

export const StyledSubHeading = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    min-width: 347px;
  }
`;
export const StyledDescriptionEmptySpace = styled.div`\
margin-bottom: ${({ theme }) => `${theme.gap.md}`};
`;
export const StyledDescription = styled(Typography)`
  padding-block: ${({ theme }) => `${theme.gap.sm} ${theme.gap.md}`};

  ${breakpoint.md} {
    padding-bottom: 0;
  }
`;

export const StyledProcesses = styled.ol`
  margin-left: 40px;
  counter-reset: item;
  list-style-type: none;
  display: grid;
  gap: 16px;

  ${breakpoint.md} {
    max-width: 730px;
    grid-template-columns: repeat(2, 1fr);
    gap: ${({ theme }) => `${theme.gap.sm}  60px`};
  }
`;

export const StyledProcess = styled.li<{ idx: number }>`
  ${({ idx, theme }) =>
    idx === -2 || idx === -1 ? '' : `border-bottom:1px solid ${theme.colors.gray40}`};
  border-top: none;
  position: relative;

  &:before {
    position: absolute;
    left: -40px;
    top: 0;
    content: counter(item, decimal-leading-zero) ' ';
    counter-increment: item;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: ${({ theme }) => theme.gap.sm};
    line-height: 20px;
    color: #bec0c5;
  }
  ${breakpoint.md} {
    border-bottom: none;
    padding-left: 40px;
    margin-left: -25px;
    ${({ idx, theme }) =>
      idx === 0 || idx === 1 ? '' : `border-top:1px solid ${theme.colors.gray40}`};
    min-height: 187px;
    padding-top: ${({ theme }) => theme.gap.md};

    &:before {
      position: absolute;
      top: ${({ theme }) => theme.gap.md};
      left: 0;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

export const StyledProcessTitle = styled(Typography)``;

export const StyledProcessDescription = styled(Typography)`
  padding-block: ${({ theme }) => `${theme.gap.xs} ${theme.gap.sm}`};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.sm};
  }
`;
