import React from 'react';
import { DefaultType } from 'types/default';

import {
  StyledColumn,
  StyledContent,
  StyledDescription,
  StyledDescriptionEmptySpace,
  StyledProcess,
  StyledProcessDescription,
  StyledProcesses,
  StyledProcessTitle,
  StyledSubHeading,
  StyledTitle,
  StyledWrapper,
} from './recruitment-process-steps.styles';

export type RecruitmentProcessStepType = {
  heading: string;
  text: string;
};

export type RecruitmentProcessStepsType = {
  heading: string;
  subTitle: string;
  stepText: string;
  processSteps: RecruitmentProcessStepType[];
} & DefaultType;

const RecruitmentProcessSteps = ({
  heading,
  subTitle,
  stepText,
  processSteps,
  padding,
  mobilePadding,
}: RecruitmentProcessStepsType) => (
  <StyledWrapper padding={padding} mobilePadding={mobilePadding}>
    <StyledContent>
      {subTitle ? (
        <StyledSubHeading variant="title6" color="gray40">
          {subTitle}
        </StyledSubHeading>
      ) : null}
      <StyledColumn>
        {heading ? <StyledTitle html={heading} variant="title2" component="h2" /> : null}
        {stepText ? (
          <StyledDescription variant="body1" html={stepText} />
        ) : (
          <StyledDescriptionEmptySpace />
        )}
        {processSteps ? (
          <StyledProcesses>
            {processSteps.map((step, idx) => (
              <StyledProcess key={step.heading} idx={idx}>
                <StyledProcessTitle variant="title3" component="h3">
                  {step.heading}
                </StyledProcessTitle>
                <StyledProcessDescription variant="body1">{step.text}</StyledProcessDescription>
              </StyledProcess>
            ))}
          </StyledProcesses>
        ) : null}
      </StyledColumn>
    </StyledContent>
  </StyledWrapper>
);
export default RecruitmentProcessSteps;
